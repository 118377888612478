@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	:root {
		--font-sans: Inter, Inter Fallback;

		--foreground-destructive: 345 82.7% 40.8%;

		--background: 40, 7%, 92%;
		--foreground: 222.2 84% 4.9%;

		--muted: 210 40% 93%;
		--muted-foreground: 215.4 16.3% 30%;

		--popover: 0 0% 100%;
		--popover-foreground: 222.2 84% 4.9%;

		--card: 0 0% 100%;
		--card-foreground: 222.2 84% 4.9%;

		--border: 214.3 31.8% 91.4%;
		--input: 214.3 31.8% 91.4%;
		--input-invalid: 0 84.2% 60.2%;

		--primary: 222.2 47.4% 11.2%;
		--primary-foreground: 210 40% 98%;

		--secondary: 210 20% 83%;
		--secondary-foreground: 222.2 47.4% 11.2%;

		--accent: 210 40% 90%;
		--accent-foreground: 222.2 47.4% 11.2%;

		--destructive: 0 70% 50%;
		--destructive-foreground: 210 40% 98%;

		--ring: 215 20.2% 65.1%;

		--radius: 0.5rem;

		/* Primary color system */
		--primary-default: 218, 54%, 20%;
		--primary-200: 218, 96%, 65%;
		--primary-300: 217, 22%, 45%;
		--primary-400: 218, 54%, 20%;
		--primary-500: 212, 53%, 17%;

		/* Secondary color system */
		--secondary-default: 40, 7%, 92%;
		--secondary-200: 0, 0%, 99%;
		--secondary-300: 0, 0%, 96%;
		--secondary-400: 40, 7%, 92%;
		--secondary-500: 0, 0%, 67%;

		/* State color system */
		--info-300: 218, 96%, 65%;
		--success-300: 146, 60%, 62%;
		--warning-300: 44, 97%, 74%;
		--error-300: 359, 94%, 73%;

		/* Black color system */
		--black-default: 210, 27%, 10%;
		--black-100: 216, 23%, 23%;
		--black-200: 210, 27%, 10%;
		--black-300: 223, 84%, 5%;
		--white-200: 0, 0%, 96%;

		/* Grey color system */
		--grey-default: 0, 0%, 67%;
		--grey-200: 240, 4%, 85%;
		--grey-300: 0, 0%, 67%;
		--grey-400: 0, 0%, 49%;
		--grey-500: 0, 0%, 26%;
		--grey-600: 0, 0%, 16%;
	}

	.dark {
		/* --background: 222.2 84% 4.9%; */
		--background: 216, 23%, 23%;
		--foreground: 210 40% 98%;

		--foreground-destructive: -4 84% 60%;

		--muted: 217.2 32.6% 12%;
		--muted-foreground: 215 20.2% 65.1%;

		--popover: 222.2 84% 4.9%;
		--popover-foreground: 210 40% 98%;

		--card: 222.2 84% 4.9%;
		--card-foreground: 210 40% 98%;

		--border: 217.2 32.6% 17.5%;
		--input: 217.2 32.6% 17.5%;
		--input-invalid: 0 62.8% 30.6%;

		--primary: 210 40% 98%;
		--primary-foreground: 222.2 47.4% 11.2%;

		--secondary: 217.2 20% 24%;
		--secondary-foreground: 210 40% 98%;

		--accent: 217.2 32.6% 10%;
		--accent-foreground: 210 40% 98%;

		--destructive: 0 60% 40%;
		--destructive-foreground: 0 85.7% 97.3%;

		--ring: 217.2 32.6% 60%;

		/* Primary color system */
		--primary-default: 218, 54%, 20%;
		--primary-200: 218, 96%, 65%;
		--primary-300: 217, 22%, 45%;
		--primary-400: 218, 54%, 20%;
		--primary-500: 212, 53%, 17%;

		/* Secondary color system */
		--secondary-default: 40, 7%, 92%;
		--secondary-200: 0, 0%, 99%;
		--secondary-300: 0, 0%, 96%;
		--secondary-400: 40, 7%, 92%;
		--secondary-500: 0, 0%, 67%;

		/* State color system */
		--info-300: 218, 96%, 65%;
		--success-300: 146, 60%, 62%;
		--warning-300: 44, 97%, 74%;
		--error-300: 359, 94%, 73%;

		/* Black color system */
		--black-default: 210, 27%, 10%;
		--black-100: 216, 23%, 23%;
		--black-200: 210, 27%, 10%;
		--black-300: 223, 84%, 5%;
		--white-200: 0, 0%, 96%;

		/* Grey color system */
		--grey-default: 0, 0%, 67%;
		--grey-200: 240, 4%, 85%;
		--grey-300: 0, 0%, 67%;
		--grey-400: 0, 0%, 49%;
		--grey-500: 0, 0%, 26%;
		--grey-600: 0, 0%, 16%;
	}
}

@layer base {
	* {
		@apply border-border;
	}
	body {
		@apply bg-background text-foreground;
	}
}
